html {
  height: 100%;
}

body {
  height: 100vh;
  background-color: #fefeff;
  color: #333330;
  font-size: 14px;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  line-height: 1.43;
}

h1,h2,h3,h4,h5,h6,h7 {
  color: #222;
}

.docs h1 {
  margin-bottom: 0.7rem;
  margin-top: 1.5rem;
}

.docs h2 {
  margin-bottom: 0.65rem;
  margin-top: 1.5rem;
}

.docs h3 {
  margin-bottom: 1.2rem;
  margin-top: 1.5rem;
}

.docs h4 {
  margin-bottom: 0.55rem;
  margin-top: 1.4rem;
}

.docs h5 {
  margin-bottom: 0.5rem;
  margin-top: 1.35rem;
}

.docs h6 {
  margin-bottom: 0.45rem;
  margin-top: 1.3rem;
}

.docs h7 {
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
}

@media (max-width: 600px) {
  button.btn, a.btn, input.btn {
    padding: 0.3em 0.5em;
    margin: 0.2em 0;
  }
}

a {
  cursor: pointer;
}

a, a:not([href]):not([tabindex]) {
  color: #337ab7;
}

.citation {
  font-style: italic;
}

.details-page-name-and-id {
  margin: 0 0 0.5em 0;
  padding: 0.6em 0 0 0;
  max-width: 1400px;
}

.details-page-name-and-id-prefix {
  color: #666;
}

@media (min-width: 1000px) {
  .details-page-name-and-id {
    font-size: 200%;
  }
}
@media (min-width: 600px) and (max-width: 1000px) {
  .details-page-name-and-id {
    font-size: 175%;
  }
}
@media (max-width: 600px) {
  .details-page-name-and-id {
    font-size: 150%;
  }
}

@media (max-width: 1100px) {
  .details-page-name-and-id {
    clear: both;
  }
}

.name-id-highlight {
  font-weight: bold;
}

.details-page-summary {
  font-size: 110%;
  border: 0;
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  padding: 0.5em 0;
  display: inline-block;
  max-width: 1400px;
  margin-bottom: 1em;
}

.details-page-summary .details-label {
  font-weight: bold;
}
@media (min-width: 800px) {
  .details-page-summary .details-label {
    white-space: nowrap;
  }
}

@media (min-width: 1300px) {
  .details-page-summary {
    min-width: 1150px;
  }
}

.details-page-summary table td {
  vertical-align: top;
  padding: 0 40px 5px 0;
}

.annotation-table table {
  width: 100%;
}

.annotation-table, .annotation-table legend, .genes-table, .genes-table legend, .genotypes-table, .genotypes-table legend {
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  border-color: #bbb;
  background-color: #F9F9F9;
}

.annotation-table, .genes-table, .genotypes-table {
  margin: 18px 0;
  padding: 0.8em 0.8em 0.1em 0.8em;
}

.annotation-table {
  position: relative;
}

.annotation-table td, .genes-table td, .genotypes-table td, .annotation-table th, .genes-table th, .genotypes-table th {
  vertical-align: top;
  padding: 0 10px 6px 0;
}

.annotation-table .annotation-table-container {
  /* https://stackoverflow.com/questions/52405038/absolute-div-inside-fieldset-with-legend */
  position: relative;
}

@media (max-width: 1200px) {
  /* see: https://github.com/pombase/website/issues/1313 */
.annotation-table .annotation-table-container {
  overflow-x: auto;
}
}

fieldset.annotation-table {
  min-width: inherit;
}

@media (min-width: 800px) {
  .annotation-table th {
    white-space: nowrap;
  }
}

.annotation-table legend, .genes-table legend, .genotypes-table legend {
  padding: 5px;
  display: block;
  font-size: 120%;
  width: auto;
  margin: 5px;
}

.term-page-view-change {
  font-size: 120%;
}

/* override Bootstrap */
html body hr {
  margin: 15px 0;
}

ul {
  padding-left: 25px;
}

.app-link {
  display: inline-block;
}

.collapse-icon {
  border: 1px solid #999;
  background-color: #FFFFFF;
  padding-left: 0.2em;
  padding-right: 0.2em;
  font-family: mono;
  text-decoration: none;
  user-select: none;
}

select[selected], option {
  font-weight: bold;
}

select option:disabled {
  color: #999;
  font-weight: normal;
}

.popover {
  max-width: 65em !important;
  pointer-events: none;
}

.menu-pos-fixed {
  position: fixed;
  top: 2px;
}
@media (min-width: 800px) {
  .details-page, .docs-content {
    right: 0;
    padding-left: 230px;
    width: auto;
  }
  .details-page-menu, .page-content-menu {
    width: 210px;
    left: 10px;
  }
  .menu-pos-absolute {
    position: absolute;
    top: 130px;
  }
}


@media (max-width: 799px) {
  .details-page-menu, .page-content-menu, .docs-menu {
    position: inherit;
    width: 20em;
    padding: 0.5em;
  }
  .small-logo-visible {
    display: none;
  }
}

.left-menu-part {
  margin-top: 7px;
  margin-bottom: 7px;
  background-color: #f0f0e3cc;
}

.left-menu-part:hover {
  background-color: #e8e8dfdd;
}

.left-menu-part a, .left-menu-part span {
  display: block;
  padding: 2.5px 4.5px;
}

.left-menu-header {
  font-size: 120%;
  margin-left: 0px;
  font-weight: bold;
  border-left: 3px solid #556;
}
.left-menu-item {
  -webkit-user-select: none;
  user-select: none;
  margin-left: 0px;
  font-size: 105%;
  border-left: 3px solid #667;
}
.left-sub-menu-item {
  -webkit-user-select: none;
  user-select: none;
  margin-left: 6px;
  font-size: 95%;
  border-left: 2px solid #778;
}
.left-sub-sub-menu-item {
  -webkit-user-select: none;
  user-select: none;
  margin-left: 12px;
  font-size: 95%;
  border-left: 1.7px solid #889;
}

@media (min-height: 675px) and (max-height: 730px) and (min-width: 800px) {
  .left-menu-item {
    font-size: 90%;
  }
  .left-sub-menu-item {
    font-size: 85%;
  }
}
@media (min-height: 610px) and (max-height: 675px) and (min-width: 800px) {
  .left-menu-item {
    font-size: 85%;
  }
  .left-sub-menu-item {
    font-size: 80%;
  }
}
@media (min-height: 580px) and (max-height: 610px) and (min-width: 800px) {
  .left-menu-item {
    font-size: 80%;
  }
  .left-sub-menu-item {
    font-size: 75%;
  }
}

@media (max-height: 580px) and (min-width: 800px) {
  .left-menu-item {
    font-size: 68%;
  }
  .left-sub-menu-item {
    font-size: 65%;
  }
  .small-logo-visible {
    display: none;
  }
}

.left-menu-part a:not([href]):not([tabindex]) {
  color: #175a9c;
}

.left-menu-part a:hover {
  text-decoration: underline;
}

.annotation-sub-table-name {
  font-size: 110%;
  font-weight: bold;
  padding: 0 0 0.4em 0;
}

.no-wrap-span {
  display: inline-block;
  white-space: nowrap;
}

.bs-callout {
  padding: 1em;
  margin: 1em 0;
  border: 1px solid #dde;
  border-left-width: 5px;
  border-radius: 3px;
  font-size: 120%;
}
.bs-callout h4 {
  margin-top: 0;
  margin-bottom: 5px;
}
.bs-callout p:last-child {
  margin-bottom: 0;
}
.bs-callout code {
  border-radius: 3px;
}
.bs-callout+.bs-callout {
  margin-top: -5px;
}
.bs-callout-default {
  border-left-color: #777;
}
.bs-callout-default h4 {
  color: #777;
}
.bs-callout-primary {
  border-left-color: #428bca;
}
.bs-callout-primary h4 {
  color: #428bca;
}
.bs-callout-success {
  border-left-color: #5cb85c;
}
.bs-callout-success h4 {
  color: #5cb85c;
}
.bs-callout-danger {
  border-left-color: #d9534f;
}
.bs-callout-danger h4 {
  color: #d9534f;
}
.bs-callout-warning {
  border-left-color: #f0ad4e;
}
.bs-callout-warning h4 {
  color: #f0ad4e;
}
.bs-callout-info {
  border-left-color: #5bc0de;
}
.bs-callout-info h4 {
  color: #5bc0de;
}


@supports (display: grid) {

@media (min-width: 800px) {
  .panel-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-gap: 3.5em 3em;
    justify-content: center;
  }
}

@media (min-width: 400px) and (max-width: 800px) {
  .info-panel {
    display: block;
    margin: 2em auto 0.5em auto;
  }
}

@media (max-width: 400px) {
  .info-panel {
    display: block;
    margin: 2em 0 0.5em 0;
  }
}

}

/* very dodgy hack to detect IE 10 and 11 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .info-panel, .front-twitter-feed {
    width: 300px;
  }

  .info-panel, .front-twitter-feed, .front-news-feed {
    display: inline-block;
    margin: 0.2em;
    min-height: 32em;
  }

  .front-news-feed {
    width: 600px;
  }
}

.newsfeed-thumbnail {
  float: left;
  border: 1px solid #ddd;
  margin: 0.1em 1em 0.2em 0;
  border-radius: 15%;
  width: 34px;
  height: 34px;
}

.recent-news .news-item {
  padding-bottom: 2em;
}

.recent-news img.screenshot {
  box-shadow: 1px 3px 8px 1px #ddd;
}

.news-archive .news-item h3 {
  margin-bottom: 0.6em;
}

.info-panel, .front-twitter-feed {
  box-shadow: 0px 0px 18px rgba(120,120,120,.80);
  vertical-align: top;
  max-width: 300px;
}

.panel-container {
  clear: both;
}

.dropdown-header {
  padding: 3px 15px;
  background-color: #e5e8f3;
  color: #444;
}

.search-box-highlight {
  font-weight: bold;
}

.search-box-organism {
  color: #707080;
}

button.active .search-box-organism {
  color: #e8e8f0;
}

@media (max-width: 600px) {
  #header-search-box .dropdown-item {
    font-size: 75%;
  }
}
@media (min-width: 600px) {
  #header-search-box .dropdown-item {
    font-size: 85%;
  }
}

.modal-header {
  border-bottom: 0;
}

.modal-footer {
  border-top: 0;
}

.modal-header, .modal-footer {
  background-color: #dce0ef;
}

.modal-footer .btn-default {
  background-color: #d5daeb;
  border-color: #b8b8b8;
}

p {
  color: #444;
}

figure {
  margin: 1em;
}
figcaption {
  margin-top: 0.7em;
  margin-bottom: 1em;
}

.btn[disabled], .btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn[disabled] {
  opacity: 0.65;
}
#content .btn {
  font-size: 0.9em;
}

@keyframes highlightRow {
  from {
    background-color: #bbbbff;
  }
  10% {
    background-color: #d7d7ff;
  }
  to {
    background-color: #ffffff;
  }
}

/* tweak bootstrap style:  */
label {
  margin-bottom: .3rem;
}

.annotation-table-filter select {
  width: 10em;
}

button.dropdown-item {
  padding: 0.2rem 0.5rem 0.2rem 1rem;
}

button.dropdown-item.active > div.term-definition {
  display: inline-block;
  width: 53em;
  font-size: 80%;
  color: #fff;
  margin: 0.1em 0.2em 0em 1em;
  max-height: 3em;
  white-space: normal;
  overflow-y: hidden;
}

a.disabled-menu-item.dropdown-item {
   color: grey;
}

.help-link img {
  height: 1.2em;
  margin: 0.1em 0.2em 0.2em 0.5em;
}

.tool-container {
  padding: 0 0.5em;
}

.tool-content {
  padding: 1em 0 1em 1em;
}

.tool-heading-help-link {
  padding-top: 0.4em;
  font-size: 110%;
  float: right;
}

.tool-heading-help-link img {
  height: 1.3em;
}

.tool-heading-title {
  margin: 0.4em 0 0.2em 0;
  font-weight: bold;
}

.completion-match-detail em {
  font-style: normal;
  font-weight: bold;
}

.annotations-section-header, .details-page-external-links {
  margin: 0.6em 0;
}

.annotations-section-header {
  font-size: 150%;
}

.details-page-external-links {
  font-size: 140%;
}

details {
  margin-bottom: 1em
}

.docs-content img.screenshot {
  box-shadow: 1px 3px 12px 1px #ccc;
  margin: 1em 0.5em;
}

.docs-colour-block {
  width: 4em;
  height: 1em;
  display: inline-block;
  vertical-align: baseline;
}
